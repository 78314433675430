//This React func component is updated based on userprofile.js at \DPTalk\Dev\login\js
//refer to DevNote: p29: Create sign-in page with AWS Cognito section


import React, { Component } from 'react';
import NavBar from './components/navbar';
import './App.css';
import verifyToken from './components/verifier';
import MyExperienceMenu from './components/myexpmenu';
import MyOpinionMenu from './components/myopmenu';
import MyExperienceForm from './components/expform';
import MyOpinionForm from './components/opform';
import PersonToTalkForm from './components/personToTalkForm';

import MyProfileMenu from './components/myprofilemenu';
import MyProfileForm from './components/profileform';

import MyChatBotMenu from './components/mychatbotmenu';
import MyChatBotForm from './components/chatbotform';

import BillingForm from './components/billingForm';

import Footer from './components/footer';

//import { Auth } from 'aws-amplify'; //FC092023
//import AmazonCognitoIdentity from 'amazon-cognito-identity-js'; 
//import { CognitoUserPool, AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';//FC092023
//import AWS from 'aws-sdk';
import { CognitoIdentityServiceProvider } from 'aws-sdk';
import AWS from 'aws-sdk/global';

//the following setup is for the app setup in region us-west-2
// * It's ok to have constant or function defined outside of component

import { useEffect } from "react";


// * since each js file has its own scope and only exported variable are visible to other files
//export them so that they can be used in verifier.js
export const domain = "missu";
export const region = "us-west-2";
export const appClientId = "67tli02it4qd0hbhpe5s1s2sd9";
export const userPoolId = "";
export const redirectURI = "";
//**For temporarily  block people to acceess the web site, 
//I use the above two lines to replace the following two lines
//export const userPoolId = "us-west-2_E5b8ou6KD";
//export const redirectURI = "https://www.missu.ai/index.html";


//start: copied methods from userprofile.js
//Convert Payload from Base64-URL to JSON
const decodePayload = payload => {
    const cleanedPayload = payload.replace(/-/g, '+').replace(/_/g, '/');
    const decodedPayload = atob(cleanedPayload)
    const uriEncodedPayload = Array.from(decodedPayload).reduce((acc, char) => {
        const uriEncodedChar = ('00' + char.charCodeAt(0).toString(16)).slice(-2)
        return `${acc}%${uriEncodedChar}`
    }, '')
    const jsonPayload = decodeURIComponent(uriEncodedPayload);

    return JSON.parse(jsonPayload)
}

//Parse JWT Payload
export const parseJWTPayload = token => {
    const [header, payload, signature] = token.split('.');
    const jsonPayload = decodePayload(payload)

    return jsonPayload
};

//Parse JWT Header
export const parseJWTHeader = token => {
    const [header, payload, signature] = token.split('.');
    const jsonHeader = decodePayload(header)

    return jsonHeader
};

//Generate a Random String
const getRandomString = () => {
    const randomItems = new Uint32Array(28);
    crypto.getRandomValues(randomItems);
    const binaryStringItems = randomItems.map(dec => `0${dec.toString(16).substr(-2)}`)
    return binaryStringItems.reduce((acc, item) => `${acc}${item}`, '');
}

//Encrypt a String with SHA256
const encryptStringWithSHA256 = async str => {
    const PROTOCOL = 'SHA-256'
    const textEncoder = new TextEncoder();
    const encodedData = textEncoder.encode(str);
    return crypto.subtle.digest(PROTOCOL, encodedData);
}

//Convert Hash to Base64-URL
const hashToBase64url = arrayBuffer => {
    const items = new Uint8Array(arrayBuffer)
    const stringifiedArrayHash = items.reduce((acc, i) => `${acc}${String.fromCharCode(i)}`, '')
    const decodedHash = btoa(stringifiedArrayHash)

    const base64URL = decodedHash.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
    return base64URL
}

//end: copied methods from userprofile.js

const getCode = () => {
    const myHeaders = new Headers();
    myHeaders.set('Cache-Control', 'no-store');
    //To access the value of the query inside the browser, using JavaScript, 
    //there is a special API called URLSearchParam, supported by all modern browsers. 
    const urlParams = new URLSearchParams(window.location.search);
    console.log("getcode()=", urlParams.get('code'));
    return urlParams.get('code');
}

const getState = () => {
    const myHeaders = new Headers();
    myHeaders.set('Cache-Control', 'no-store');
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('state');
}

// * seems like these functions can be extracted to a different file instead of mixing up with UI component
// * since these are pure bussiness logic
const isCodePresent = () => {
    /*const myHeaders = new Headers();
    myHeaders.set('Cache-Control', 'no-store');
    const urlParams = new URLSearchParams(window.location.search);
    //var tokens;
    return urlParams.get('code') == null;*/
    console.log("isCodePresent()=", getCode(), " condition result:", getCode() == null);
    return getCode() !== null;
}

const requestCode = async () => {

    console.log("code is null");
    // Create random "state"
    const state = getRandomString();
    sessionStorage.setItem("pkce_state", state);

    // Create PKCE code verifier
    const code_verifier = getRandomString();
    sessionStorage.setItem("code_verifier", code_verifier);

    // Create code challenge
    const arrayHash = await encryptStringWithSHA256(code_verifier);
    const code_challenge = hashToBase64url(arrayHash);
    sessionStorage.setItem("code_challenge", code_challenge)

    console.log("https://" + domain + ".auth." + region + ".amazoncognito.com/oauth2/authorize?response_type=code&state=" + state + "&client_id=" + appClientId + "&redirect_uri=" + redirectURI + "&scope=openid&code_challenge_method=S256&code_challenge=" + code_challenge);
    // Redirtect user-agent to /authorize endpoint
    // location.href has the current webpage's uri, when assign another url to it, it will redirect the current page to the new url
    window.location.href = "https://" + domain + ".auth." + region + ".amazoncognito.com/oauth2/authorize?response_type=code&state=" + state + "&client_id=" + appClientId + "&redirect_uri=" + redirectURI + "&scope=openid&code_challenge_method=S256&code_challenge=" + code_challenge;
}



const fetchOAuth2Tokens = (code_verifier) => {
    return fetch("https://" + domain + ".auth." + region + ".amazoncognito.com/oauth2/token?grant_type=authorization_code&client_id=" + appClientId + "&code_verifier=" + code_verifier + "&redirect_uri=" + redirectURI + "&code=" + getCode(), {
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }).then((response) => {
        return response.json();
    })

}

const signOut = () => {
    sessionStorage.clear();
    // window.location.href = redirectURI;
    // https://mydomain.auth.us-east-1.amazoncognito.com/logout?response_type=code&client_id=myclientid&logout_uri=https://myapp/getthemoutofhere/byebye.aspx 
    window.location.href = "https://" + domain + ".auth." + region + ".amazoncognito.com/logout?response_type=code" + "&client_id=" + appClientId + "&logout_uri=" + redirectURI;
    console.log("signOut() is called",)
}

const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
}

// * this is a component in function format
// * define variable with let and const instead of var to avoid scope leaking caused by var
// * for exmaple, variable defined with var inside if block would be visible outside of if block
// * using let instead to get a similar experience like java
// * const is like final
function App() {


    // * this is the state of this component
    // * whenever the value of state changed, component will re-render
    // * we use set function to change state after ajax call is finished
    // * we also use these state in render

    //idToken is a decoded token string
    let [idToken, setIdToken] = React.useState();

    //accessToken is a decoded token string
    let [accessToken, setAccessToken] = React.useState();
    //let [refreshToken, setRefreshToken] = React.useState(); //FC092023
    //let [cognitoUserName, setCognitoUserName] = React.useState(); //FC092023
    let [error, setError] = React.useState();
    let [userInfo, setUserInfo] = React.useState();

    //token includes idToken, accessToken, refreshToken, and all still in (base64)Encoded string
    //need the encoded idToken in the request header to do API call w/ customAuthorizer.
    let [token, setToken] = React.useState(); //yin to fix fetch runtime issue.

    let [menuItemClicked, setMenuItemClicked] = React.useState("instruction");
    // setMenuItemClicked("postLoginHome"); 

    // let [iframeLoaded, setIframeLoaded] = React.useState(0);

    let [expMenuItem, setExpMenuItem] = React.useState();
    let [opMenuItem, setOpMenuItem] = React.useState();

    //  let [personToTalk, setPersonToTalk] = React.useState();
    let [loader, setLoader] = React.useState(null); //chatbot loader.

    let [talkButtonClicked, setTalkButtonClicked] = React.useState(0);

    let [profileMenuItem, setProfileMenuItem] = React.useState();
    let [chatBotMenuItem, setChatBotMenuItem] = React.useState();

    //for femal bot text
    //let [loader2, setLoader2] = React.useState(null); //chatbot loader2.

    //let [sexOfPersonToTalk, setSexOfPersonToTalk] = React.useState();
    let [chatbotModelReady, setChatbotModelReady] = React.useState();
    let [emailToTalk, setEmailToTalk] = React.useState(" "); //the email of the person to talk.
    let [talkFromEmail, setTalkFromEmail] = React.useState(" ") //the email of the person doing the talk, i.e. .the login user.
    let [talkTimestamp, setTalkTimestamp] = React.useState(" ") //the timestamp used to create video chat result file name in personToTalkForm.jsx

    //Per Yin: the clearState() is no need becasue when logout page refreshed, all the state values are gone
    //Per Yin, also, when set idToken to emtpy, sould do setIdToken(""); same for others.
    const clearState = () => {
        idToken = "";
        accessToken = "";
        // refreshToken = ""; //FC 092023
        // cognitoUserName = ""; //FC092023
        error = "";
        userInfo = "";
        token = "";
        //  iframeLoaded=0;
        expMenuItem = "";
        opMenuItem = "";
        //   personToTalk="";
        loader = null;
        talkButtonClicked = 0;

        profileMenuItem = "";
        chatBotMenuItem = "";
        //sexOfPersonToTalk = "";
        chatbotModelReady = "";
        emailToTalk = "";
        talkFromEmail = "";
        talkTimestamp = "";

        console.log("clearStae() is called", userInfo);
    };

    //*****FC092023 starts: for making refresh token work, i.e. for refresh id token b/f expire
    //******this one works by using cognito initiateAuth API, refresh token, and reset token state via setToken().
    //End2End tested on 09/25/2023, it works including 
    //1) refresh id and access token when time up; 
    //2) API calls using the refreshed new id token (e.g. personToTalkForm.jsx's handleTalk() call an API by using a refreshed id token in its header' authorizer)
    async function checkAndRefreshIDToken(curTokens) {
        console.log("checkAndrefreshIDToken starts....");
        try {

            const curRefreshToken = curTokens.refresh_token;
            // console.log("checkAndRefreshIdToken, refresh_token...", curRefreshToken);

            //console.log("checkAndRefreshIdToken, token...", token);
            const refreshInterval = setInterval(async () => {
                //refer to useEffect(, [])'s setIdToken(JSON.stringify(parseJWTPayload(tokens.id_token), null, '\t'));

                const curIdToken = parseJWTPayload(curTokens.id_token);
                /*let curIdToken;
                if(idToken) {
                    console.log("checkAndRefreshIdToken....idToken is not undefined");
                    curIdToken = JSON.parse(idToken); 
                } else {
                    console.log("checkAndRefreshIdToken....idToken is undefined");
                    curIdToken = parseJWTPayload(curTokens.id_token)
                }*/

                // console.log("checkAndRefreshIdToken, curIdToken= ", JSON.stringify(curIdToken));
                // console.log("checkAndRefreshIDToken, exp = ", curIdToken.exp );

                //console.log("checkAndRefreshIdToken, before get CognitoUserName.");

                const curUserName = curIdToken['cognito:username'];
               // console.log("*****checkAndRefreshIDToken, cognito:username:", curUserName);

                //console.log("checkAndRefreshIdToken, refresh_token", curTokens.refresh_token);

                // const curRefreshToken = curTokens.refresh_token; 

                // Set up a timer to refresh the access token 5 minutes before it expires
                const expirationTime = curIdToken.exp; // exp time in seconds

              //  console.log("checkAndrefreshIDToken..expirationTime=", expirationTime * 1000);

                const currentTime = new Date().getTime(); //getTime() returns the number of milliseconds
              //  console.log("checkAndrefreshIDToken..current time:", currentTime);

                // if (currentTime + 3300000 >= expirationTime*1000) {  //If the id token is to expire in the next 55 minutes(for dev test), refresh it 
                if (currentTime + 300000 >= expirationTime * 1000) {// If the access token is about to expire in the next 5 minutes, refresh it

                  //  console.log("checkAndrefreshIDToken, close to expiration...refresh token....");
                    //FCstart

                    AWS.config.region = region;

                   // console.log("checkAndrefreshIDToken, ---1");
                    const params = {
                        AuthFlow: 'REFRESH_TOKEN_AUTH',
                        ClientId: appClientId,
                        AuthParameters: {
                            'REFRESH_TOKEN': curRefreshToken,
                            'USERNAME': curUserName,
                        },
                    };

                   // console.log("checkAndrefreshIDToken, --2");

                    const cognitoISP = new CognitoIdentityServiceProvider();
                  //  console.log("checkAndrefreshIDToken, --3");

                    try {
                        const response = await cognitoISP.initiateAuth(params).promise();
                      //  console.log("checkAndrefreshIDToken, --4");
                        const newAccessToken = response.AuthenticationResult.AccessToken;
                       // console.log("checkAndrefreshIDToken, --5");
                        const newIdToken = response.AuthenticationResult.IdToken;

                        console.log('New Access Token:', newAccessToken);
                        console.log('New ID Token:', newIdToken);

                        curTokens.id_token = newIdToken;
                        curTokens.access_token = newAccessToken;
                        setToken(curTokens);
                      //  console.log("checkAndRefreshIdToken, a/f setToken()....udpated token....", curTokens);

                        setIdToken(JSON.stringify(parseJWTPayload(newIdToken), null, '\t'));
                        setAccessToken(JSON.stringify(parseJWTPayload(newAccessToken), null, '\t'));

                        //As set<StateVariable>(), like setIdToken(),etc. are ASync func. 
                        //The following console.log will show idToken and accessToken are "undefined",
                        //But it is OK, Not a problem....it eventually set the new idToken and accessToken correctly.
                        // console.log("checkAndrefreshIDToken, --6.....set new idToken: ", idToken);
                        // console.log("checkAndrefreshIDToken, --7...set new accessToken: ", accessToken);

                        // You can use the new access and ID tokens here
                    } catch (error) {
                        console.error('Error refreshing tokens in setInterval()...', error);
                    }

                } else {
                   // console.log("in checkAndRefreshIdToken, not expired yet, IdToken expiration timestamp is: ", curIdToken.exp * 1000);
                }
            }, 60000); // Check every minute (adjust as needed)

            //Per Yin, this return() line is not reallyneeded because when a user logout, web go to another URI/page. the setInterval() will be removed.
            // Clean up the interval when the component unmounts
            return () => clearInterval(refreshInterval);
        } catch (error) {
            console.error("Error in checkAndRefreshIDToken()...", error);
        }
    }

    /*
    ////import AWS from 'aws-sdk';
    // This one using cognito adminInitiateAuth API is not working as it requires my aws acct credientials, but it is web app, not secure.
    async function checkAndRefreshIDToken(curTokens) {
        console.log("checkAndrefreshIDToken starts....");
        // Configure AWS Amplify with your Cognito settings
        try {
            
            //setIdToken(JSON.stringify(parseJWTPayload(tokens.id_token), null, '\t'));
          
            const curIdToken = parseJWTPayload(curTokens.id_token);
            console.log("checkAndRefreshIdToken, curIdToken= ", JSON.stringify(curIdToken));
            console.log("checkAndRefreshIDToken, exp = ", curIdToken.exp );
    
            console.log("checkAndRefreshIdToken, before get CognitoUserName.");
          
            console.log("*****checkAndRefreshIDToken, cognito:username:", curIdToken['cognito:username']);
            const curUserName = curIdToken['cognito:username'];
    
            console.log("checkAndRefreshIdToken, refresh_token", curTokens.refresh_token);
    
            const curRefreshToken = curTokens.refresh_token; //parseJWTPayload(curTokens.refresh_token);
             
            //console.log("*****checkAndRefreshIDToken, refreshtoken:", JSON.stringify(curRefreshToken));
    
          // Set up a timer to refresh the access token 5 minutes before it expires
          const expirationTime =  curIdToken.exp; // exp time in seconds
    
          console.log("checkAndrefreshIDToken...b/f setInterval(), expirationTime=", expirationTime);
          const refreshInterval = setInterval(async () => {
            const currentTime = new Date().getTime(); //getTime() returns the number of milliseconds
            console.log("checkAndrefreshIDToken...in setInterval starts...current time:", currentTime);
            if (currentTime + 3300000 >= expirationTime*1000) {  //If the id token is to expire in the next 50 minutes, refresh it
            //if (currentTime + 300000 >= expirationTime) {
              // If the access token is about to expire in the next 5 minutes, refresh it
               
                console.log("checkAndrefreshIDToken, in setInterval()..close to expiration...cur refresh token.", curRefreshToken);
                //FCstart
                // Configure AWS Cognito
                const cognito = new AWS.CognitoIdentityServiceProvider({ region: region});
                console.log("checkAndrefreshIDToken, ---1");
                const params = {
                    AuthFlow: 'REFRESH_TOKEN_AUTH',
                    ClientId: appClientId,
                    UserPoolId: userPoolId,
                    AuthParameters: {
                      'REFRESH_TOKEN': curRefreshToken,
                    },
                  };
                console.log("checkAndrefreshIDToken, --2");
    
                cognito.adminInitiateAuth(params, (err, data) => {
                    if (err) {
                      console.error('Error refreshing tokens:', err);
                      return;
                    }
    
                    console.log("checkAndrefreshIDToken, --3");
                
                    // The refreshed tokens are in data.AuthenticationResult
                    const newAccessToken = data.AuthenticationResult.AccessToken;
                    console.log("checkAndrefreshIDToken, --4");
                    const newIdToken = data.AuthenticationResult.IdToken;
                
                    console.log('New Access Token:', newAccessToken);
                    console.log('New ID Token:', newIdToken);
                
                    // You can use the new access and ID tokens here
                  });
            } else {
                console.log("in checkAndRefreshIdToken, not expired yet, IdToken expiration timestamp is: ", curIdToken.exp);
            }
          }, 60000); // Check every minute (adjust as needed)
    
          // Clean up the interval when the component unmounts
          return () => clearInterval(refreshInterval);
        } catch (error) {
          console.error('Error fetching tokens:', error);
        }
      } */


    /*
    //This is a way to implement refresh token with amazon-cognito-identity-js.
    //But I got error when run cognitoUser.refreshSession(...)
    //import { CognitoUserPool, AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';//FC092023
    
    async function checkAndRefreshIDToken(curTokens) {
        console.log("checkAndrefreshIDToken starts....");
        // Configure AWS Amplify with your Cognito settings
        try {
            const poolData = {
                UserPoolId: userPoolId,
                ClientId: appClientId,
            };
            
            const userPool = new CognitoUserPool(poolData);
    
            console.log("checkAndrefreshIDToken ...a/f userPool created.");
    
            //setIdToken(JSON.stringify(parseJWTPayload(tokens.id_token), null, '\t'));
          
            const curIdToken = parseJWTPayload(curTokens.id_token);
            console.log("checkAndRefreshIdToken, curIdToken= ", JSON.stringify(curIdToken));
            console.log("checkAndRefreshIDToken, exp = ", curIdToken.exp );
    
            console.log("checkAndRefreshIdToken, before get CognitoUserName.");
          
            console.log("*****checkAndRefreshIDToken, cognito:username:", curIdToken['cognito:username']);
            const curUserName = curIdToken['cognito:username'];
    
            console.log("checkAndRefreshIdToken, refresh_token", curTokens.refresh_token);
    
            const curRefreshToken = curTokens.refresh_token; //parseJWTPayload(curTokens.refresh_token);
             
            //console.log("*****checkAndRefreshIDToken, refreshtoken:", JSON.stringify(curRefreshToken));
    
          // Set up a timer to refresh the access token 5 minutes before it expires
          const expirationTime =  curIdToken.exp; // exp time in seconds
    
          console.log("checkAndrefreshIDToken...b/f setInterval(), expirationTime=", expirationTime);
          const refreshInterval = setInterval(async () => {
            const currentTime = new Date().getTime(); //getTime() returns the number of milliseconds
            console.log("checkAndrefreshIDToken...in setInterval starts...current time:", currentTime);
            if (currentTime + 3300000 >= expirationTime*1000) {  //If the id token is to expire in the next 50 minutes, refresh it
            //if (currentTime + 300000 >= expirationTime) {
              // If the access token is about to expire in the next 5 minutes, refresh it
               
                console.log("checkAndrefreshIDToken, in setInterval()..close to expiration...need refresh token.");
                //FCstart
                const authenticationData = {
                    RefreshToken: curRefreshToken,
                };
    
                console.log("checkAndrefreshIDToken, ---1");
                
                const authenticationDetails = new AuthenticationDetails(authenticationData);
    
                console.log("checkAndrefreshIDToken, --2");
                
                const userData = {
                    Username: curUserName, // Replace with the username of the user
                    Pool: userPool,
                };
    
                console.log("checkAndrefreshIDToken, --3");
                
                const cognitoUser = new CognitoUser(userData);
    
                console.log("checkAndrefreshIDToken, --4");
                
                cognitoUser.refreshSession(authenticationDetails, (err, session) => {
                    if (err) {
                    console.error('checkAndrefreshIDToken, Error refreshing tokens:', err);
                    return;
                    }
    
                    if (session ){
                        console.log("checkAndRefreshIDToken, session", session);
                    }
                    
                    if(session.getAccessToken){
                        console.log("checkAndRefreshIDToken, session.getAccessToken", session.getAccessToken);
                    }
                    if(session.getIdToken) {
                        console.log("checkAndRefreshIDToken, session.getIdToken", session.getIdToken);
                    }
    
                    console.log("checkAndrefreshIDToken, --5");
                
                    const newAccessToken = session.getAccessToken().getJwtToken();
    
                    console.log("checkAndrefreshIDToken, --6");
                    const newIdToken = session.getIdToken().getJwtToken();
    
                    console.log('New Access Token:', newAccessToken);
                    console.log('New ID Token:', newIdToken);
                    //FC end
                    
                });
            } else {
                console.log("in checkAndRefreshIdToken, not expired yet, IdToken expiration timestamp is: ", curIdToken.exp);
            }
          }, 60000); // Check every minute (adjust as needed)
    
          // Clean up the interval when the component unmounts
          return () => clearInterval(refreshInterval);
        } catch (error) {
          console.error('Error fetching tokens:', error);
        }
      } */

    /* seems aws-amplify may not work with my current login/auth code. try using cognito jdk api above.
    //import AmazonCognitoIdentity from 'amazon-cognito-identity-js'; 
    
    async function checkAndRefreshIDToken() {
        console.log("checkAndrefreshIDToken starts....");
        // Configure AWS Amplify with your Cognito settings
        Auth.configure({
            Auth: {
           // identityPoolId: 'your-identity-pool-id',
            region: region,
            userPoolId: userPoolId,
            userPoolWebClientId: appClientId,
            },
        });
        console.log("checkAndrefreshIDToken ...b/f try{}.");
        try {
            const curIdToken =  JSON.parse(idToken);
            console.log("checkAndRefreshIdToken, curIdToken= ", curIdToken);
            console.log("checkAndRefreshIDToken, exp = ", curIdToken.exp );
    
           // console.log("checkAndrefreshIDToken...fctestuer...", fctestuser);
          // Check if a session with valid tokens exists
          const session = await Auth.currentSession();
          
          console.log("checkAndrefreshIDToken...right a/f call Auth.currentSession()..." );
    
          // Set up a timer to refresh the access token 5 minutes before it expires
          const expirationTime = session.getIdToken().getExpiration() * 1000; // Convert to milliseconds
    
          console.log("checkAndrefreshIDToken...b/f setInterval(), expirationTime=", expirationTime);
          const refreshInterval = setInterval(async () => {
            const currentTime = new Date().getTime();
            console.log("checkAndrefreshIDToken...in setInterval starts....", currentTime);
            if (currentTime + 13500000 >= expirationTime) {  //If the id token is to expire in the next 45 minutes, refresh it
            //if (currentTime + 300000 >= expirationTime) {
              // If the access token is about to expire in the next 5 minutes, refresh it
              
              const refreshedSession = await Auth.currentSession({ refreshToken: session.getRefreshToken().getToken() });
              const newIdToken = refreshedSession.getIdToken().getJwtToken();
              setIdToken(newIdToken);
              console.log("in checkAndRefreshIdToken, newIdToken is:", newIdToken);
            } else {
                console.log("in checkAndRefreshIdToken, not expired yet, IdToken is: ", session.getIdToken())
            }
          }, 60000); // Check every minute (adjust as needed)
    
          // Clean up the interval when the component unmounts
          return () => clearInterval(refreshInterval);
        } catch (error) {
          console.error('Error fetching tokens:', error);
        }
      }
    */
    //FC092023 ends  for making refresh token work

    // const[menuItem, setMenuItem] = React.useState(); //for menu item/button click/state management.

    // * since the 2nd parameter of thie useEffect function is empty array
    // * this function will only be triggered once when component is loaded initially
    // * if second parameter is not empty, which means it has state associted with it. the first parameter/func will be called
    // * whenever the associated state has update/change.
    useEffect(() => {
        //If code not present then request code else request tokens
        if (isCodePresent()) {

            console.log("code is NOT null");
            console.log("code is:", getCode());

            // Verify state matches
            const state = getState();
            if (sessionStorage.getItem("pkce_state") != state) {
                setError("Invalid state");
            } else {

                // console.log("https://" + domain + ".auth." + region + ".amazoncognito.com/oauth2/token?grant_type=authorization_code&client_id=" + appClientId + "&code_verifier=" + code_verifier + "&redirect_uri=" + redirectURI + "&code=" + getCode());
                // Fetch OAuth2 tokens from Cognito
                const code_verifier = sessionStorage.getItem('code_verifier');

                fetchOAuth2Tokens(code_verifier).then((data) => {
                    // Verify id_token
                    let tokens = data;
                    console.log("****frank1:", tokens);
                    var idVerified = verifyToken(tokens.id_token);
                    Promise.resolve(idVerified).then(function (value) {
                        // if verified, i.e. same, localcompare will return 0. otherwise, verifyToken retuned value string 
                        // "large than" 'verified", the localCompare will return 1...then reture error.
                        if (value.localeCompare("verified")) {
                            setError("Invalid ID Token - " + value);
                            return;
                        }
                    }).then(function () {
                        // * set tokens to display and trigger following ajax calls
                        setToken(tokens); //yin add to fix runtime fetch issue.
                        console.log("****frank2:", tokens);

                        setIdToken(JSON.stringify(parseJWTPayload(tokens.id_token), null, '\t'));
                        setAccessToken(JSON.stringify(parseJWTPayload(tokens.access_token), null, '\t'));

                        console.log("*****just login, idtoken:", JSON.stringify(tokens.id_token));
                        console.log("*****just login, accesstoken:", JSON.stringify(tokens.access_token));

                        //FC092023 start
                        /*
                         const tempIdToken = tokens.id_token; //JSON.parse(idToken);
                         setCognitoUserName(JSON.stringify(tempIdToken.get["cognito:username"])); //cognito:username
                         
                         console.log("*****just login, cognito:username:", JSON.stringify(cognitoUserName));
 
                          //setRefreshToken(JSON.stringify(parseJWTPayload(tokens.refresh_token), null, '\t'));
                          setRefreshToken(JSON.stringify(tokens.refresh_token));
                          console.log("*****just login, refreshtoken:", JSON.stringify(tokens.refresh_token));
                         */
                        //FC092023 end

                    }).then(function () { //FC092023 added this then.function for refresh id token for not timeout in one hour
                        console.log("APP.js before calling checkAnRefreshIDToken().");
                        checkAndRefreshIDToken(tokens);

                    });

                });
            }
        }
    }, []);

    // * whenever accessToken, idToken's value changed, we will trigger this function
    // * we check if both them are present, then fetch user info
    useEffect(() => {
        if (accessToken && idToken) {
            // Fetch from /user_info
            console.log("https://" + domain + ".auth." + region + ".amazoncognito.com/oauth2/userInfo");
            fetch("https://" + domain + ".auth." + region + ".amazoncognito.com/oauth2/userInfo", {
                method: 'post',
                headers: {
                    'authorization': 'Bearer ' + token.access_token
                }
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    // Display user information
                    setUserInfo(JSON.stringify(data, null, '\t'));
                });

            //FC added the following condition block on 11/28/21. Not sure really need it or not
            //added it due to some wierd app behavior on 11/28/21.
            /*  if(loader != null ) {
               console.log("App.js useEffect on (accesstoken, idtoken), loader is not null");

               loader.api.deleteSession(); //somehow, it did not really clean the talk session on the chatbot iframe.

               //document.getElementsByTagName('iframe')[0].style.visibility = "hidden";

               setLoader(null);
              
            }*/
        }
    }, [accessToken, idToken]);



    // * whenever menuItemClicked's value changed, we will trigger this function
    // * we check if userInfo is present and menuItemCliked == talkwithDP, then load lex chatbot
    useEffect(() => {
        console.log("**Fc:useEffect for menuItemClicket = ", menuItemClicked);
        console.log("**Fc: document.element.botLoader=", document.getElementById("botLoader"));

        if (userInfo) {

            if (menuItemClicked.localeCompare("talkWithDP") == 0) {
                console.log("App.js menuItemClicked is talkwithDP");

                //frank added for lex chat  
                // document.getElementById('chatif').src = 'https://d30nt3s0tf9yqg.cloudfront.net/index.html';


                /*  if(iframeLoaded == 1) {
                      document.getElementsByTagName('iframe')[0].style.visibility = "visible";
                  } */
                /*else {
                    let myScript = document.createElement("script");
                    myScript.setAttribute("src", "https://d2abwykqp9wemr.cloudfront.net/lex-web-ui-loader.min.js");
                    myScript.setAttribute("id","botLoader");
                    document.body.appendChild(myScript);
                    myScript.addEventListener("load", scriptLoaded, false);

                    function scriptLoaded() {
                        console.log("Script is ready to rock and roll!");
                        var loaderOpts = {
                            baseUrl: 'https://d2abwykqp9wemr.cloudfront.net/',
                            shouldLoadMinDeps: true
                        };

                        //var loader = new ChatBotUiLoader.IframeLoader(loaderOpts);
                        // Critical: need add window. before ChatBotUiLoader...otherwise, ChatBotUiLoader will be undefined.
                        var loader = new window.ChatBotUiLoader.IframeLoader(loaderOpts); 
                        let loginUserEmail = JSON.parse(idToken).email;

                        var loaderCfg = {lex: {sessionAttributes: {userEmail:loginUserEmail}}};
                        loader.load(loaderCfg).catch(function (error) { console.error(error); });
                    
                      //  loader.load()
                      //      .catch(function (error) { console.error(error); });
                    
                    }

                   setIframeLoaded(1);
                }*/
            } else {
                if (loader != null) {
                    console.log("App.js menuitemclicked is not talkWithDP and loader is not null");

                    //  loader.api.deleteSession(); //somehow, it did not really clean the talk session on the chatbot iframe.
                    if (document.getElementsByTagName('iframe')[0] != null) {
                        document.getElementsByTagName('iframe')[0].style.visibility = "hidden";
                    } else {
                        console.log("***1: loader is not null, but iframe[0] is null.");
                    }


                    setTalkButtonClicked(0);

                    /*
                    setIframeLoaded(0);
 
                    delete window.ChatBotUiLoader;
 
                    let myframe =  document.getElementsByTagName('iframe')[0];
                    myframe.remove();
    
                    let myScript = document.getElementById("botLoader");  
                    myScript.remove();*/


                }
            }
        }
    }, [menuItemClicked]);

    useEffect(() => {
        console.log("In app.js, expMenuItem=", expMenuItem);
        // setOpMenuItem("");
    }, [expMenuItem]);

    useEffect(() => {
        console.log("In app.js, opMenuItem=", opMenuItem);
        // setExpMenuItem("");
    }, [opMenuItem]);

    useEffect(() => {
        console.log("In app.js, profileMenuItem=", profileMenuItem);
    }, [profileMenuItem]);

    useEffect(() => {
        console.log("In app.js, chatBotMenuItem=", chatBotMenuItem);
    }, [chatBotMenuItem]);

    useEffect(() => {
        console.log("In app.js, emailToTalk=", emailToTalk);
        // setOpMenuItem("");
    }, [emailToTalk]);

    useEffect(() => {
        console.log("In app.js, talkFromEmail=", talkFromEmail);
        // setOpMenuItem("");
    }, [talkFromEmail]);

    /*useEffect(() => {
        console.log("In app.js, sexOfPersonToTalk=", sexOfPersonToTalk);
        // setOpMenuItem("");
    }, [sexOfPersonToTalk]);*/

    useEffect(() => {
        console.log("In app.js, chatbotModelReady=", chatbotModelReady);
        // setOpMenuItem("");
    }, [chatbotModelReady]);

    useEffect(() => {
        console.log("In app.js, updated token..."); //, token);
        // setOpMenuItem("");
    }, [token]);




    useEffect(() => {
        console.log("**Fc:useEffect for talkButtonClicked");

        /*
        // console.log ("**Fc: document.element.botLoader=", document.getElementById("botLoader"));
        // console.log("**FC: useEffect for talkButtonClicket,loader,  talkButtonClicked = ", loader, talkButtonClicked);
        if (userInfo && talkButtonClicked > 0) {

            console.log("App.js, use useEffect for talkButtonClicked, which is: ", talkButtonClicked);

            if (sexOfPersonToTalk.localeCompare("F") == 0) {
                console.log("App.js, sexOfPersonToTalk is F.");
                //let newwindow = window.open('https://d270dtgr677pvv.cloudfront.net/','_blank');


                let newwindow = window.open('', '_blank');

                if (newwindow.document != null) {
                    console.log("****3: newwindow.document is not null");
                } else {
                    console.log("****4: newwindow.document is  null");
                    //var doc = document.implementation.createHTMLDocument('DPTalk'); 
                    //newwindow.document.body.innerHTML = "HTML";

                }

                console.log("App.js new talkButtonClicked effect....start to create FullPage new chat bot.");
                let myScript2 = newwindow.document.createElement("script");
                myScript2.setAttribute("src", "https://deeszq6iwj5p5.cloudfront.net/lex-web-ui-loader.min.js");
                myScript2.setAttribute("id", "botLoader2");
                newwindow.document.body.appendChild(myScript2);
                myScript2.addEventListener("load", scriptLoaded2, false);

                function scriptLoaded2() {
                    console.log("App.js, Script2 is ready to rock and roll!");
                    let loaderOpts2 = {
                        baseUrl: 'https://deeszq6iwj5p5.cloudfront.net', //it is the WeAppUrl value in female-lex-web-ui stack's outputs
                        shouldLoadMinDeps: true
                    };

                    //var loader = new ChatBotUiLoader.IframeLoader(loaderOpts);
                    // Critical: need add window. before ChatBotUiLoader...otherwise, ChatBotUiLoader will be undefined.
                    let loader2 = new newwindow.ChatBotUiLoader.FullPageLoader(loaderOpts2);
                    //  let loader2 = new window.ChatBotUiLoader.IframeLoader(loaderOpts2);
                    //  setLoader(loader);
                    //  let loginUserEmail = JSON.parse(idToken).email;

                    // let personToTalk = JSON.parse(idToken).email;  //i.e. defaul is the login user.
                    let personToTalk2 = emailToTalk;
                    // let loaderCfg = {lex: {sessionAttributes: {userEmail:personToTalk}}};
                    let loaderCfg2 = { lex: { v2BotLocaleId: "en_GB", sessionAttributes: { userEmail: personToTalk2 } } };

                    loader2.load(loaderCfg2)
                        .then(function () {
                            console.log('new FullPage chatbot UI loaded with loaderCfig2 =', loaderCfg2);

                            //120521 starts
                            // newwindow.document.title = "DPTalk App";
                            // console.log("newwindow.document title is: ", newwindow.document.getElementsByTagName("title"));
                            if (newwindow.document.readyState == "complete") {
                                console.log("newwindow document is loaded");
                            } //this if block not really needed, just for debugging purpose.

                            //lex-web-ui library does not provide an easy way to change
                            //the full page loaded window title (i.e. "Flower Orade Bot")
                            //I have to hack it with the following code to change the page title.
                            //change the  lex-web-ui-loader-config.json  to add ui.pageTitle does not work.
                            sleep(800).then(() => {
                                newwindow.document.title = "DPTalk App";
                            })
                            //120521 ends
                        }).catch(function (error) { console.error(error); });
                }

                newwindow.document.title = "DPTalk App"; //120521,need this one also to show the right title asap.
                setTalkButtonClicked(0);


            } else {
                console.log("App.js, sexOfPersonToTalk is M.");
                //****FC: somehow, sometimes, for mysterious reason, the loader is not null, but iframe is null.
                //****FC: not sure why loader is not null, even before loader is created at line#547.
                //****FC: to resolve the problem, add ifram !=null to ensure not causing errors.
                if (loader != null && document.getElementsByTagName('iframe')[0] != null) {
                    console.log("****App.js, useEffect, userInof and talkButtonClicked both are not empty, loader is not null.");

                    // loader.api.deleteSession(); //somehow, this one not really clean chatbot talk history on iframe chat bot
                    //need try the condition as sometime, the app has complain on 
                    //"TypeError: Cannot read properties of undefined (reading 'style')"
                    if (document.getElementsByTagName('iframe')[0] != null) {
                        document.getElementsByTagName('iframe')[0].style.visibility = "visible";
                    } else {
                        console.log("****2: loader is not null, but iframe[0] is null.");
                    }

                    let messageStr = "I would like";
                    loader.api.postText(messageStr, "en_US");

                    let messageStr2 = emailToTalk;

                    sleep(2000).then(() => {

                        loader.api.postText(messageStr2, "en_US");
                    })

                    //let messageStr2 = emailToTalk;
                    // loader.api.postText(messageStr2,"en_US");



                } else {
                    console.log("App.js new talkButtonClicked effect....start to create iframe new chat bot.");
                    let myScript = document.createElement("script");
                    myScript.setAttribute("src", "https://d2abwykqp9wemr.cloudfront.net/lex-web-ui-loader.min.js");
                    myScript.setAttribute("id", "botLoader");
                    document.body.appendChild(myScript);
                    myScript.addEventListener("load", scriptLoaded, false);

                    function scriptLoaded() {
                        console.log("App.js, Script is ready to rock and roll!");
                        let loaderOpts = {
                            baseUrl: 'https://d2abwykqp9wemr.cloudfront.net/',
                            shouldLoadMinDeps: true
                        };

                        //var loader = new ChatBotUiLoader.IframeLoader(loaderOpts);
                        // Critical: need add window. before ChatBotUiLoader...otherwise, ChatBotUiLoader will be undefined.
                        loader = new window.ChatBotUiLoader.IframeLoader(loaderOpts);
                        setLoader(loader);
                        //  let loginUserEmail = JSON.parse(idToken).email;

                        // let personToTalk = JSON.parse(idToken).email;  //i.e. defaul is the login user.
                        let personToTalk = emailToTalk;
                        // let loaderCfg = {lex: {sessionAttributes: {userEmail:personToTalk}}};
                        let loaderCfg = { lex: { v2BotLocaleId: "en_US", sessionAttributes: { userEmail: personToTalk } } };

                        loader.load(loaderCfg)
                            .then(function () {
                                console.log('new chatbot UI loaded with loaderCfig =', loaderCfg);

                                
                            }).catch(function (error) { console.error(error); });


                    }

                }

                setTalkButtonClicked(0);
            }
        }
        */

    }, [talkButtonClicked]);


    // * here goes the html elements just like render function in class component
    console.log("***userInfo is: ", userInfo);
    //clearState(); 
    //signOut();

    return (
        <div> {
            error ? (<div> error: {error}</div>)
                : (<div>
                    {userInfo ? (

                        <div style={{
                            backgroundImage: `url("/images/img3-m.jpg")`,
                            width: `auto`,
                            height: `1563px`,
                        }}>
                            <NavBar
                                getLoginFlag={() => { return true; }}
                                signOutMenuOnClick={() => { clearState(); signOut(); }}
                                buildMyDPMenuOnClick={() => { setMenuItemClicked("buildMyDP"); }}
                                talkWithDPMenuOnClick={() => { setMenuItemClicked("talkWithDP"); }}
                                instructionMenuOnClick={() => { setMenuItemClicked("instruction"); }}
                                contactMenuOnClick={() => { setMenuItemClicked("contact"); }}
                                billingMenuOnClick={() => { setMenuItemClicked("billing"); }}
                            />

                            {
                                menuItemClicked.localeCompare("buildMyDP") == 0 ? (
                                    <div>
                                        <h1 style={{
                                            textAlign: 'center',
                                            color: 'white',
                                        }}>
                                            Build My Legacy
                                        </h1>
                                        <table BORDER="0" CELLSPACING="10">
                                            <tr>
                                                <td>
                                                    <MyProfileMenu
                                                        profileMenuItemOnClick={(emi) => { setProfileMenuItem(emi); setExpMenuItem(""); setOpMenuItem(""); setChatBotMenuItem(""); }}
                                                    />
                                                </td>
                                                <td>
                                                    <MyExperienceMenu
                                                        expMenuItemOnClick={(emi) => { setExpMenuItem(emi); setOpMenuItem(""); setProfileMenuItem(""); setChatBotMenuItem(""); }}
                                                    />
                                                </td>
                                                <td>
                                                    <MyOpinionMenu
                                                        opMenuItemOnClick={(omi) => { setOpMenuItem(omi); setExpMenuItem(""); setProfileMenuItem(""); setChatBotMenuItem(""); }}
                                                    />
                                                </td>
                                                <td>
                                                    <MyChatBotMenu
                                                        chatBotMenuItemOnClick={(emi) => { setChatBotMenuItem(emi); setProfileMenuItem(""); setExpMenuItem(""); setOpMenuItem("") }}
                                                    />
                                                </td>
                                            </tr>
                                        </table>
                                        <br></br>
                                        <div>
                                            {
                                                expMenuItem ? (
                                                    <div>
                                                        <MyExperienceForm
                                                            expType={expMenuItem}
                                                            token={token.id_token}
                                                            email={JSON.parse(idToken).email}
                                                        />
                                                    </div>

                                                ) : (
                                                    opMenuItem ? (
                                                        <div>
                                                            <MyOpinionForm
                                                                opTopic={opMenuItem}
                                                                token={token.id_token}
                                                                email={JSON.parse(idToken).email}
                                                            />
                                                        </div>

                                                    ) : (
                                                        profileMenuItem ? (
                                                            <div>
                                                                <MyProfileForm
                                                                    profileType={profileMenuItem}
                                                                    token={token.id_token}
                                                                    email={JSON.parse(idToken).email}
                                                                />

                                                            </div>

                                                        ) : (
                                                            chatBotMenuItem ? (
                                                                <div>
                                                                    <MyChatBotForm
                                                                        chatBotMenuItem={chatBotMenuItem}
                                                                        token={token.id_token}
                                                                        email={JSON.parse(idToken).email}
                                                                    />

                                                                </div>

                                                            ) : (
                                                                <div>
                                                                    <h5 className="text-without-bg">
                                                                        To build your legacy, please select My Profile, My Experience or My Opinion menu button above to enter your profile, experience or opioion to start.
                                                                        <br></br>
                                                                        <br></br>
                                                                        After you complete your Profile, Experience and Opinion, please select My ChatBot to create your own personized ChatBot.
                                                                    </h5>
                                                                </div>
                                                            )
                                                        )
                                                    )
                                                )
                                            }
                                        </div>
                                    </div>

                                ) : (
                                    <div>
                                        {
                                            menuItemClicked.localeCompare("talkWithDP") == 0 ? (
                                                <div>
                                                    <h1 style={{
                                                        textAlign: 'center',
                                                        color: 'white',
                                                    }}>
                                                        Talk with a Legacy
                                                    </h1>
                                                    <br></br>
                                                    <PersonToTalkForm
                                                        token={token.id_token}
                                                        talkFrom={JSON.parse(idToken).email}
                                                        email={emailToTalk}
                                                        chatbotModelReady={chatbotModelReady}
                                                        talkTimestamp={talkTimestamp}
                                                        personToTalkOnClick={(chatbotModelReady, email) => {
                                                            setChatbotModelReady(chatbotModelReady);
                                                            setEmailToTalk(email);
                                                            setTalkButtonClicked(talkButtonClicked + 1);
                                                        }}
                                                        videoTalkOnClick={(talkTimestamp) => {
                                                            setTalkTimestamp(talkTimestamp);
                                                        }}
                                                    />
                                                </div>
                                            ) : (
                                                <div>
                                                    {
                                                        menuItemClicked.localeCompare("instruction") == 0 ? (
                                                            <div>
                                                                <h1 style={{
                                                                    textAlign: 'center',
                                                                    color: 'white',
                                                                }}>
                                                                    Instruction: How to Use Missu.AI
                                                                </h1>
                                                                <br></br>
                                                                <h5 className="text-without-bg">
                                                                    <ul>
                                                                        <li>
                                                                            Missu.AI enables you to build your lasting living legacy, and chat with your loved ones when remembering them.
                                                                        </li>
                                                                        <li>
                                                                            To build your lasting living legacy with your own thoughts, voice and lively face, please click "Build My Legacy" Menu.
                                                                            <ul>
                                                                                <li>
                                                                                    After clicking "Build My Legacy", you may enter information about your basic profile, some life experience, opinions on some subjects, and some digital info for your own chatbot.
                                                                                </li>
                                                                                <li>
                                                                                    The "My ChatBot" menu under "Build My Legacy" has three sub menu items/steps (listed below), each of which should be completed before your legacy is ready.
                                                                                    <ul>
                                                                                        <li>
                                                                                            Your Video: Upload a photo or a 3-second video showing your face.
                                                                                        </li>
                                                                                        <li>
                                                                                            Your Voice: Setup your voice. You may use the default ones (one for male, one for female).
                                                                                            <br></br>
                                                                                            Note: If you want to create your own cloned voice. Please contact us at support@missu.ai. We will help you to do it.
                                                                                            <br></br>
                                                                                            {/*For the next version of Missu.AI, we will enable you to create your own voice through our website directly.*/}
                                                                                        </li>
                                                                                        <li>
                                                                                            Your Thoughts: It will use the information you entered in basic profile, some life experience, and opinions on some subjects.
                                                                                            <br></br>
                                                                                            <bold>Hence, It is important that you enter the minimum info: basic profile with self introduction, at least one life expeirience, and at least one opinion on a subject before clicking the submenu: Create My Model under Menu: My ChatBot</bold>
                                                                                            <br></br>
                                                                                            Note:
                                                                                            <br></br>
                                                                                            The more information you provide about your life experience and opinions, the better your chatbot will align with "your real thoughts". Certainly, you may always come back to edit and add more info about yourself later.
                                                                                        </li>
                                                                                    </ul>

                                                                                </li>
                                                                            </ul>
                                                                        </li>
                                                                        <li>
                                                                            To chat with a "legacy", please click "Talk With Legacy" Menu.
                                                                            <ul>
                                                                                <li>
                                                                                    First, you need enter the email for the "legacy" you would like to chat with, and click check button so that Missu.AI will confirm if the specific email address has a "legacy" built up already or not.
                                                                                </li>
                                                                                <li>
                                                                                    Second, if a "legacy" for the email exists, you will see "Chat Input" text field to ask you enter your questions to start the chat.
                                                                                </li>
                                                                                <li>
                                                                                    Third, after you click "Talk" button, you will get the response from the "legacy" in the "ChatBot Response" text field.
                                                                                </li>
                                                                                <li>
                                                                                    Fouth, after you get the "legacy" response, and want to see the "legacy" video response, you may click "Video Talk" button under "ChatBot Response" text field.
                                                                                </li>
                                                                                <li>
                                                                                    Note: To get "Video Talk" response, it may takes about 1-3 minutes, depending on the size of the response. We will reduce the "waiting" time further in our next version of Missu.AI.
                                                                                    <br></br>
                                                                                    For now, please be a little patient with the "legacy". Thank you.
                                                                                </li>
                                                                            </ul>
                                                                        </li>
                                                                    </ul>
                                                                    <br>
                                                                    </br>
                                                                    Contact Us: For any questions or suggestions, please email us at support@missu.ai.
                                                                </h5>
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                {
                                                                    menuItemClicked.localeCompare("contact") == 0 ? (
                                                                        <div>
                                                                            <h1 style={{
                                                                                textAlign: 'center',
                                                                                color: 'white',
                                                                            }}>
                                                                                Contact Us
                                                                            </h1>
                                                                            <br></br>

                                                                            <h5 className="text-without-bg">
                                                                                For any questions or suggestions, please contact us at support@missu.ai.
                                                                            </h5>
                                                                        </div>
                                                                    ) : (
                                                                        <div>
                                                                            {
                                                                                 menuItemClicked.localeCompare("billing") == 0 ? (
                                                                                    <div>
                                                                                        <h1 style={{
                                                                                            textAlign: 'center',
                                                                                            color: 'white',
                                                                                        }}>
                                                                                            Billing Info Here
                                                                                        </h1>
                                                                                        <br></br>
                                                                                        <BillingForm
                                                                                            token={token.id_token}
                                                                                            userEmail={JSON.parse(idToken).email}
                            
                                                                                        />
                                                                                    </div>
                                                                                ):(
                                                                                    <div>
                                                                                        <br></br>
                                                                                        <h5 className="text-without-bg">
                                                                                            <ul>
                                                                                                <li>
                                                                                                    If you are not sure how to use Missu.AI, please click "Instruction" Menu to check it out.
                                                                                                </li>
                                                                                                <li>
                                                                                                    If you want to build your lasting living legacy, please click "Build My Legacy" Menu.
                                                                                                </li>
                                                                                                <li>
                                                                                                    If you want to chat with a "legacy", please click "Talk With Legacy" Menu.
                                                                                                </li>
                                                                                            </ul>
                                                                                        </h5>
                                                                                    </div>
                                                                                    )
                                                                            }
                                                                        </div>
                                                                        )
                                                                }
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        )
                                        }
                                    </div>
                                )
                            }
                        </div>
                    ) : (
                        <div style={{
                            backgroundImage: `url("/images/img2-s.jpg")`,
                            width: `auto`,
                            height: `732px`,
                        }}>
                            <NavBar signInButtonOnClick={() => requestCode()}
                                preLoginContactMenuOnClick={() => { setMenuItemClicked("preLoginContact"); }}
                            />

                            {
                                menuItemClicked.localeCompare("preLoginContact") == 0 ? (
                                    <div>
                                        <h1 style={{
                                            textAlign: 'center',
                                            color: 'white',
                                        }}>
                                            Contact Us
                                        </h1>
                                        <br></br>

                                        <h5 className="text-without-bg">
                                            For any questions or suggestions, please contact us at support@missu.ai.
                                        </h5>
                                    </div>
                                ) : (
                                    <div>
                                        <br></br>
                                        <h1 style={{
                                            textAlign: 'center',
                                            color: 'white',
                                        }}> Welcome to MissU.AI (Beta, Invitation Only)  </h1>
                                        <br></br>
                                        <h3 style={{
                                            textAlign: 'center',
                                            color: 'white',
                                        }}> A Place You Can Build Your Lasting Living Legacy With Your "Soul".
                                        </h3>
                                        <h6 style={{
                                            textAlign: 'center',
                                            color: 'white',
                                        }}> (Publicly Open 10:00am-12:00pm/PST, Every Saturday.)
                                        </h6>
                                        <h3 style={{
                                            textAlign: 'center',
                                            color: 'white',
                                        }}>A Place You Can Chat With Loved Ones When Remembering Them.
                                        </h3>
                                        <h6 style={{
                                            textAlign: 'center',
                                            color: 'white',
                                        }}> (Publicly Open 10:00am-12:00pm/PST, Every Saturday.)
                                        </h6>
                                        <h3 style={{
                                            textAlign: 'center',
                                            color: 'white',
                                        }}>And Above/Beyond (Coming...).
                                        </h3>

                                    </div>
                                )
                            }
                        </div>
                    )}

                </div>
                )
        }
            <Footer />
        </div>

    );
}

export default App;
