import React, { Component } from 'react';
//import $ from 'jquery'; 

//https://blog.campvanilla.com/reactjs-dropdown-menus-b6e06ae3a8fe

class MyProfileMenu extends Component {

   constructor(props) {
        super(props);
        //this.handleMenuClick = this.handleMenuClick.bind(this);
       // console.log("in MyExpereinceMenu component contractor, props: ", props);  
       // console.log("in MyExpereinceMenu component contractor, this.props: ", this.props);  
      }


    state = {
        isOpen: false
      };
    
    toggleOpen = () => { 
        this.setState({ isOpen: !this.state.isOpen }); 
        
      }
  
    render() { 
        const menuClass = `dropdown-menu${this.state.isOpen ? " show" : ""}`;
        return ( 

            <div class="btn-group" onClick={this.toggleOpen}>
                <button type="button" class="btn btn-info dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                    My Profile
                </button>

                <ul class={menuClass}  >
                    <li><a class="dropdown-item" value="Basic"  onClick={(e)=>{e.preventDefault();this.props.profileMenuItemOnClick("Basic") }} >Basic</a></li>
                </ul>

            </div>
 
             
         );
    }
} 

export default MyProfileMenu;