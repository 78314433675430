import React, { Component } from 'react';

 
class MyOpinionMenu extends Component {
    state = {
        isOpen: false
      };
    
      toggleOpen = () => this.setState({ isOpen: !this.state.isOpen });


    render() { 
        const opMenuClass = `dropdown-menu${this.state.isOpen ? " show" : ""}`;
        return ( 

            <div class="btn-group" onClick={this.toggleOpen}>
                <button type="button" class="btn btn-info dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                    My Opinion
                </button>

                <ul class={opMenuClass}> 
                    {/*<li><a class="dropdown-item" onClick={(e)=>{e.preventDefault();this.props.opMenuItemOnClick("Vote") }}>Who to Vote</a></li>*/}
                    <li><a class="dropdown-item" onClick={(e)=>{e.preventDefault();this.props.opMenuItemOnClick("Life") }}>About Life</a></li>
                    <li><a class="dropdown-item" onClick={(e)=>{e.preventDefault();this.props.opMenuItemOnClick("Education") }}>About Education</a></li>
                    <li><a class="dropdown-item" onClick={(e)=>{e.preventDefault();this.props.opMenuItemOnClick("Success") }}>About Success</a></li>
                    <li><a class="dropdown-item" onClick={(e)=>{e.preventDefault();this.props.opMenuItemOnClick("Friendship") }}>About Friendship</a></li>
                    <li><a class="dropdown-item" onClick={(e)=>{e.preventDefault();this.props.opMenuItemOnClick("Family") }}>About Family</a></li>
                    <li><a class="dropdown-item" onClick={(e)=>{e.preventDefault();this.props.opMenuItemOnClick("Marriage") }}>About Marriage</a></li>
                    <li><a class="dropdown-item" onClick={(e)=>{e.preventDefault();this.props.opMenuItemOnClick("Money") }}>About Money</a></li>
                    <li><a class="dropdown-item" onClick={(e)=>{e.preventDefault();this.props.opMenuItemOnClick("Politics") }}>About Politics</a></li>
                    <li><a class="dropdown-item" onClick={(e)=>{e.preventDefault();this.props.opMenuItemOnClick("Country") }}>About Home Country</a></li>
                    <li><a class="dropdown-item" onClick={(e)=>{e.preventDefault();this.props.opMenuItemOnClick("Career") }}>About Career</a></li>
                    <li><a class="dropdown-item" onClick={(e)=>{e.preventDefault();this.props.opMenuItemOnClick("Food") }}>About Food</a></li>
                    <li><a class="dropdown-item" onClick={(e)=>{e.preventDefault();this.props.opMenuItemOnClick("Sports") }}>About Sports</a></li>
                    <li><a class="dropdown-item" onClick={(e)=>{e.preventDefault();this.props.opMenuItemOnClick("Health") }}>About Health</a></li>
                    <li><a class="dropdown-item" onClick={(e)=>{e.preventDefault();this.props.opMenuItemOnClick("Travel") }}>About Travel</a></li>
                    <li><a class="dropdown-item" onClick={(e)=>{e.preventDefault();this.props.opMenuItemOnClick("Other") }}>About Other Topic</a></li>
                </ul>

            </div>

            
         );
    }
} 

export default MyOpinionMenu;