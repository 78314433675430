import React, { Component } from 'react';
import axios from 'axios';
import url from 'url';
import '../App.css';

 
class MyExperienceForm extends Component {
  
  constructor(props) {
        super(props);
       // const apiUrl = 'https://j1nbknreme.execute-api.us-west-2.amazonaws.com/DP-fctest/{myexpproxy+}';  
      }

    state = {
         menuItemSelected : ""
      };

  

      //refer to https://reactjs.org/docs/forms.html#controlled-components
  handleSave = async (token, email) => {
    
    console.log(" save button clicked, handleSave() start....token is:", token);

    const expInput = document.getElementById("lifeExperienceTextarea").value;

    const wordcount = expInput.split(" ").length;

    if (wordcount>1000) {
      alert ("Your input has " + wordcount + " words. Please reduce it to less than 100 words");
    } else {
        //*** the API path naming could be better.
        // I missed resource path when config APIG, like <root>/myexperience/. I forget to creat resource: myexperience in APIG.
        //howvere, as it works, just let it go.
        //const apiUrl = 'https://j1nbknreme.execute-api.us-west-2.amazonaws.com/DP-fctest/{myexpproxy+}'; 
        const apiUrl = ' https://c0o8gnzty3.execute-api.us-west-2.amazonaws.com/prod/experience';
        const data = { 
            "email": email,
            "expType" : this.state.menuItemSelected,
            "expInput" : expInput,
            "eventFlag": "experience"
        };
        const header = {
            headers: {
                'Authorization': `${token}`
              // 'Authorization': `${token}` + 'ftest-bad-token-tail'  //this is for testing cognito authorizer, it works (ie. blocked or get error) if we add any extra chars in the true id_token.
              // 'Authorization': `Basic ${token}` 
            }
          };

          
        console.log("in handleSave(), header is:", header);
        
        axios.post(apiUrl, data, header)
        .then((response) => {
            console.log("handlesave() get response:", response);
            alert("Your life experience is saved successfully"); 
        }).catch((error) => {
            console.log("handlesave() get error:", error);
        });
    }
  }
    
//refer to https://reactjs.org/docs/forms.html#controlled-components
  getLifeExpValue = async (ptoken, pemail) => {
    console.log(" getLifeExpValue start....token is:", ptoken);
    let returnValue="";
      

   // const apiUrl2 = 'https://j1nbknreme.execute-api.us-west-2.amazonaws.com/DP-fctest/{myexpproxy+}';  
   const apiUrl2 = ' https://c0o8gnzty3.execute-api.us-west-2.amazonaws.com/prod/experience';
  
    const data = { 
        "email": pemail,
        "expType" : this.state.menuItemSelected,
        "expInput" : "",
        "eventFlag": "experience"
    };
    const header = {
        headers: {
            'Authorization': `${ptoken}`
          // 'Authorization': `${token}` + 'ftest-bad-token-tail'  //this is for testing cognito authorizer, it works (ie. blocked or get error) if we add any extra chars in the true id_token.
        // 'Authorization': `Basic ${token}` 
        }
      }

    
    console.log("in expform.getLifeExpValue(), header is:", header);
    
    axios.post(apiUrl2, data, header)
    .then((response) => {
        console.log("getLifeExpValue()  is :", response);
        console.log("response.data is:", response.data );
        returnValue = JSON.parse(JSON.stringify(response.data)).value;
        console.log("getLifeExpValue=", returnValue);
        document.getElementById("lifeExperienceTextarea").value = returnValue; //JSON.stringify(returnValue);
        
      }).catch((error) => {
        console.log("getLifeExpValue() get error:", error);
      }); 
  

  // console.log("getLifeExpValue=", returnValue);
  //  return JSON.stringify(returnValue);
  //  document.getElementById("lifeExperienceTextarea").value = returnValue;

  }

  componentDidMount() {
    this.getLifeExpValue(this.props.token, this.props.email);
  }

  componentDidUpdate() { //after the page loaded/created already and after something changed/updated in the same page/component, e.g. a dropdownlist changed to another item.
    console.log("expform.jsx: componentDidUpdate!");
    this.getLifeExpValue(this.props.token, this.props.email);
  } 

    render() { 
        this.state.menuItemSelected = this.props.expType.toLowerCase();
        console.log("MyExperienceForm state is: ", this.state.menuItemSelected);
        return ( 

            <div>
                <h5 style={{ color: 'white'}}>
                        Please enter your {this.props.expType} Life Experience in less than 100 words in the follwoing text field and save it. 
                </h5>
                <div class="form-group">
                    <label for="lifeExperienceTextarea">
                    </label>
                    <textarea class="form-control" id="lifeExperienceTextarea" rows="10">
                     </textarea>
                </div>
                <div class="col-md-12 text-center">
                    <button  class="btn btn-primary" onClick = {() => this.handleSave(this.props.token, this.props.email)}>Save</button>
                </div>
                
           

            </div>

            
         );
    }
} 

export default MyExperienceForm;