import React, { Component } from 'react';
//import $ from 'jquery'; 

//https://blog.campvanilla.com/reactjs-dropdown-menus-b6e06ae3a8fe

class MyChatBotMenu extends Component {

   constructor(props) {
        super(props);
        //this.handleMenuClick = this.handleMenuClick.bind(this);
       // console.log("in MyExpereinceMenu component contractor, props: ", props);  
       // console.log("in MyExpereinceMenu component contractor, this.props: ", this.props);  
      }


    state = {
        isOpen: false
      };
    
    toggleOpen = () => { 
        this.setState({ isOpen: !this.state.isOpen }); 
        
      }
  
    render() { 
        const menuClass = `dropdown-menu${this.state.isOpen ? " show" : ""}`;
        return ( 

            <div class="btn-group" onClick={this.toggleOpen}>
                <button type="button" class="btn btn-info dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                    My ChatBot
                </button>

                <ul class={menuClass}  >
                    <li><a class="dropdown-item" value="SetMyVoice"  onClick={(e)=>{e.preventDefault();this.props.chatBotMenuItemOnClick("upload your video") }} >Upload My Video or Picture</a></li>      
                    <li><a class="dropdown-item" value="SetMyVoice"  onClick={(e)=>{e.preventDefault();this.props.chatBotMenuItemOnClick("set your voice uuids") }} >Setup My Voice</a></li>     
                    <li><a class="dropdown-item" value="CreateMyData" onClick={(e)=>{e.preventDefault();this.props.chatBotMenuItemOnClick("create your model data") }} >Create My Model</a></li>
                   
                   {
                    //Temperarily comment out this menu item until integrating w/ resemble.ai's creating voice API after purchasing its pro member plan
                    //This menu item is working: it is let a user to upload its offline 30 recorded voice files in a zip and uploaded to S3.
                   // <li><a class="dropdown-item" value="CloneMyVoice"  onClick={(e)=>{e.preventDefault();this.props.chatBotMenuItemOnClick("clone your voice") }} >Clone Your Voice</a></li>
                   }
                    { //120123:no need the following two if using langchain since they are for openai finetune model creation/check
                    //<li><a class="dropdown-item" value="CreateMyModel"  onClick={(e)=>{e.preventDefault();this.props.chatBotMenuItemOnClick("create your chatbot model") }} >Create My ChatBot Model</a></li>
                    //<li><a class="dropdown-item" value="CheckMyModel"  onClick={(e)=>{e.preventDefault();this.props.chatBotMenuItemOnClick("check your chatbot model status") }} >Check My ChatBot Model Status</a></li>
                    }
                </ul>

            </div>
 
             
         );
    }
} 

export default MyChatBotMenu;