import React, { Component } from 'react';
import axios from 'axios';
import '../App.css';
 
class MyOpinionForm extends Component {

    constructor(props) {
        super(props);
      }

    state = {
         menuItemSelected : ""
      };

  

      //refer to https://reactjs.org/docs/forms.html#controlled-components
    handleOpSave = async (token, email) => {
        
        console.log(" opForm...save button clicked, handleSave() start....token is:", token);

        //  alert("opfrom.jsx handleSave start....1, email="+ email);

        const opInput = document.getElementById("lifeOpinionTextarea").value;

        //alert("opfrom.jsx handleSave start....2, opInput="+ opInput);

        const wordcount = opInput.split(" ").length;

        if (wordcount>1000) {
            alert ("Your input has " + wordcount + " words. Please reduce it to less than 100 words");
        } else {
           // const apiUrl = 'https://j1nbknreme.execute-api.us-west-2.amazonaws.com/DP-fctest/{myexpproxy+}';  
           const apiUrl = ' https://c0o8gnzty3.execute-api.us-west-2.amazonaws.com/prod/opinion'; 
           const data = { 
                "email": email,
                "opTopic" : this.state.menuItemSelected,
                "opInput" : opInput,
                "eventFlag": "opinion"
            };
            const header = {
                headers: {
                    'Authorization': `${token}`
                    // 'Authorization': `${token}` + 'ftest-bad-token-tail'  //this is for testing cognito authorizer, it works (ie. blocked or get error) if we add any extra chars in the true id_token.
                // 'Authorization': `Basic ${token}` 
                }
            };

        
            console.log("in opForm. handleSave(), header is:", header);
            // alert("opfrom.jsx handleSave start....3, header="+ header+", data=" + data.opTopic +", "+data.email+", "+data.opInput);
            
            axios.post(apiUrl, data, header).then((response) => {
            // alert("opfrom.jsx handleSave start response:", response);

                console.log("opForm.handlesave() get response:", response);
                alert("Your life opinion is saved successfully"); 
            }).catch((error) => {
                console.log("opForm.handlesave() get error:", error);
            //  alert("opfrom.jsx handleSave catech error", error);

            });
        }
  }
  
  //refer to https://reactjs.org/docs/forms.html#controlled-components
   getLifeOpinion = async (ptoken, pemail) => {
   // alert("opfrom.jsx getLifeOpinion() start....1");

      console.log(" getLifeOpinion start....token is:", ptoken);
      let returnValue="";
       

   // const apiUrl2 = 'https://j1nbknreme.execute-api.us-west-2.amazonaws.com/DP-fctest/{myexpproxy+}';  
   const apiUrl2 = ' https://c0o8gnzty3.execute-api.us-west-2.amazonaws.com/prod/opinion';
   
     const data = { 
         "email": pemail,
         "opTopic" : this.state.menuItemSelected,
         "opInput" : "",
         "eventFlag": "opinion"
     };
     const header = {
         headers: {
             'Authorization': `${ptoken}`
           // 'Authorization': `${token}` + 'ftest-bad-token-tail'  //this is for testing cognito authorizer, it works (ie. blocked or get error) if we add any extra chars in the true id_token.
          // 'Authorization': `Basic ${token}` 
         }
       };

     
     console.log("in opForm.getLifeOpinion(), header is:", header);
     
    axios.post(apiUrl2, data, header)
     .then((response) => {
         console.log("opFrom.getLifeOpinion()  is :", response);
         console.log("OpForm.getLifeOpinion response.data is:", response.data );
         returnValue = JSON.parse(JSON.stringify(response.data)).value;
         console.log("opForm.getLifeOpinion=", returnValue);
         document.getElementById("lifeOpinionTextarea").value = returnValue; //JSON.stringify(returnValue);
          
     }).catch((error) => {
         console.log("getLifeExpValue() get error:", error);
     }); 
   

    // console.log("getLifeExpValue=", returnValue);
   //  return JSON.stringify(returnValue);
 //  document.getElementById("lifeExperienceTextarea").value = returnValue;

    }

    
      //to save the opinion-who to vote topic.
    handleOpSave4Vote = async (token, email) => {
        console.log(" opForm...save button clicked, handleSave4Vote() start....token is:", token);


      const selectgpp1 = document.getElementById('goodPersonPriority');
      const valuegpp1 = selectgpp1.options[selectgpp1.selectedIndex].value;
      console.log("opfrom.jsx handleSave ....1, valuegpp1="+ valuegpp1);

      
      const selectgpp2 = document.getElementById('goodPolicyPriority');
      const valuegpp2 = selectgpp1.options[selectgpp2.selectedIndex].value;

      
      const selectgep = document.getElementById('goodExpPriority');
      const valuegep = selectgep.options[selectgep.selectedIndex].value;

      
      const selectpp1 = document.getElementById('partyPriority');
      const valuepp1 = selectpp1.options[selectpp1.selectedIndex].value;

      
      const selectpp2 = document.getElementById('prefParty');
      const valuepp2 = selectpp2.options[selectpp2.selectedIndex].value;


     //alert("opfrom.jsx handleSave start....2, opInput="+ opInput);

     //const apiUrl = 'https://j1nbknreme.execute-api.us-west-2.amazonaws.com/DP-fctest/{myexpproxy+}';
     const apiUrl = ' https://c0o8gnzty3.execute-api.us-west-2.amazonaws.com/prod/opinion';
      const data = { 
          "eventFlag": "opinionVote",
          "email": email,
          "opTopic" : this.state.menuItemSelected,
          "goodPersonPriority": valuegpp1,
          "goodPolicyPriority": valuegpp2,
          "goodExpPriority":valuegep,
          "partyPriority": valuepp1,
          "prefParty": valuepp2,
          "action": "save"
          
      };
      const header = {
          headers: {
              'Authorization': `${token}`
            // 'Authorization': `${token}` + 'ftest-bad-token-tail'  //this is for testing cognito authorizer, it works (ie. blocked or get error) if we add any extra chars in the true id_token.
           // 'Authorization': `Basic ${token}` 
          }
        };

      
      console.log("in opForm. handleSave4Vote(), header is:", header, ", data is: ", data);
     // alert("opfrom.jsx handleSave start....3, header="+ header+", data=" + data.opTopic +", "+data.email+", "+data.opInput);
      
      axios.post(apiUrl, data, header).then((response) => {
       // alert("opfrom.jsx handleSave start response:", response);

          console.log("opForm.handlesave4Vote() get response:", response);
          alert("Your life opinion- Vote is saved successfully"); 
      }).catch((error) => {
          console.log("opForm.handlesave4Vote() get error:", error);
      //  alert("opfrom.jsx handleSave catech error", error);

      });
  }
  
  getLifeOpinion4Vote = async (ptoken, pemail) => {
    // alert("opfrom.jsx getLifeOpinion4Vote() start....1");
 
       console.log(" getLifeOpinion4Vote start....token is:", ptoken);
       let goodPerson ="";
       let goodPolicy = "";
       let goodExp = "";
       let partyPri = "";
       let prefParty = "";
       let noDataFlag = "";
        
 
        //const apiUrl2 = 'https://j1nbknreme.execute-api.us-west-2.amazonaws.com/DP-fctest/{myexpproxy+}';  
        const apiUrl2 = ' https://c0o8gnzty3.execute-api.us-west-2.amazonaws.com/prod/opinion';
    
      const data = { 
          "email": pemail,
          "opTopic" : this.state.menuItemSelected,
          "eventFlag": "opinionVote",
          "action": "get"
      };
      const header = {
          headers: {
              'Authorization': `${ptoken}`
            // 'Authorization': `${token}` + 'ftest-bad-token-tail'  //this is for testing cognito authorizer, it works (ie. blocked or get error) if we add any extra chars in the true id_token.
           // 'Authorization': `Basic ${token}` 
          }
        };
 
      
      console.log("in opForm.getLifeOpinion4Vote(), header is:", header, "data is:", data);
      
     axios.post(apiUrl2, data, header)
      .then((response) => {
          console.log("opFrom.getLifeOpinion4Vote(), response  is :", response);
          console.log("OpForm.getLifeOpinion4Vote() response.data is:", response.data );
         
          noDataFlag = JSON.parse(JSON.stringify(response.data)).noDataFlag;
          console.log("noDataFlag=", noDataFlag);
          if(noDataFlag == "false") {
            console.log("in noDataFlage is false if condition");
            goodPerson = JSON.parse(JSON.stringify(response.data)).goodPersonPriority;
            goodPolicy = JSON.parse(JSON.stringify(response.data)).goodPolicyPriority;
            goodExp =  JSON.parse(JSON.stringify(response.data)).goodExperiencePriority;
            partyPri= JSON.parse(JSON.stringify(response.data)).partyPriority;
            prefParty = JSON.parse(JSON.stringify(response.data)).preferredParty;
            
            document.getElementById("goodPersonPriority").selectedIndex = goodPerson;
            document.getElementById("goodPolicyPriority").selectedIndex = goodPolicy;
            document.getElementById("goodExpPriority").selectedIndex = goodExp;
            document.getElementById("partyPriority").selectedIndex = partyPri;
            document.getElementById("prefParty").selectedIndex = prefParty;

          } else {
            console.log("opFrom.getLifeOpinion4Vote() noDataFlag is true, response is :", response);
            
          }
          
           
      }).catch((error) => {
          console.log("getLifeExpValue() get error:", error);
      }); 
    
 
     // console.log("getLifeExpValue=", returnValue);
    //  return JSON.stringify(returnValue);
  //  document.getElementById("lifeExperienceTextarea").value = returnValue;
 
     }

    componentDidMount() { // called when this component/page first time created/loaded, called after render().
        console.log("opform.jsx: componentDidMount!")

        if (this.state.menuItemSelected == "Vote" ) {
            console.log("in componentDidMount(), Who to Vote if condition is true.");
            this.getLifeOpinion4Vote(this.props.token, this.props.email);
        } else {
            this.getLifeOpinion(this.props.token, this.props.email);
        }

      }

      
    componentDidUpdate() { //after the page loaded/created already and after something changed/updated in the same page/component, e.g. a dropdownlist changed to another item.
        console.log("opform.jsx: componentDidUpdate!");

       // if (this.state.menuItemSelected == "Who to Vote" ) {
        if (this.state.menuItemSelected == "Vote" ) {
            console.log("in componentDidUpdate(), Who to Vote if condition is true.");
            this.getLifeOpinion4Vote(this.props.token, this.props.email);
        } else {
            this.getLifeOpinion(this.props.token, this.props.email);
        }

      } 

    render() { 
        this.state.menuItemSelected = this.props.opTopic;
        console.log("MyOpinionForm state is: ", this.state.menuItemSelected);
        return ( 
            <div>
            { 
                //this.props.opTopic.localeCompare("Who to Vote")==0 ? ( 
                this.props.opTopic.localeCompare("Vote")==0 ? ( 
                    <div>
                        <h5 className = "text-without-bg">
                            Please enter your Opinion - {this.props.opTopic}: set the priorities of your voting consideriations, and save it.
                        </h5> 
                        <div class="form-row">
                            <div class="col-auto">
                                <label class="my-1 mr-2" for="goodPersonPriority">
                                <h6 className = "text-without-bg"> Good Person </h6> 
                                </label>
                                <select class="custom-select mr-sm-2" id="goodPersonPriority">
                                    <option selected>Choose Priority...</option>
                                    <option value="1">1st</option>
                                    <option value="2">2nd</option>
                                    <option value="3">3rd</option>
                                    <option value="4">4th</option>
                                </select>
                            </div>
                            <div class="col-auto">
                                <label class="my-1 mr-2" for="goodPolicyPriority">
                                <h6 className = "text-without-bg"> Good Policy </h6> 
                                </label>
                                <select class="custom-select mr-sm-2" id="goodPolicyPriority">
                                    <option selected>Choose Priority...</option>
                                    <option value="1">1st</option>
                                    <option value="2">2nd</option>
                                    <option value="3">3rd</option>
                                    <option value="4">4th</option>
                                </select>
                            </div>
                            <div class="col-auto">
                                <label class="my-1 mr-2" for="goodExpPriority">
                                <h6 className = "text-without-bg"> Good Experience </h6> 
                                </label>
                                <select class="custom-select mr-sm-2" id="goodExpPriority">
                                    <option selected>Choose Priority...</option>
                                    <option value="1">1st</option>
                                    <option value="2">2nd</option>
                                    <option value="3">3rd</option>
                                    <option value="4">4th</option>
                                </select>
                            </div>
                            <div class="col-auto">
                                <label class="my-1 mr-2" for="partyPriority">
                                <h6 className = "text-without-bg"> Preferred Party </h6> 
                                </label>
                                <select class="custom-select mr-sm-2" id="partyPriority">
                                    <option selected>Choose Priority...</option>
                                    <option value="1">1st</option>
                                    <option value="2">2nd</option>
                                    <option value="3">3rd</option>
                                    <option value="4">4th</option>
                                </select>
                            </div>
                            <div class="col-auto">
                                <label class="my-1 mr-2" for="prefParty">
                                <h6 className = "text-without-bg"> Which Party Do You Prefer </h6> 
                                </label>
                                <select class="custom-select mr-sm-2" id="prefParty">
                                    <option selected>Choose Priority...</option>
                                    <option value="1">Democratic</option>
                                    <option value="2">Republic</option>
                                    <option value="3">Independant</option>
                                    <option value="4">Does not Matter</option>
                                </select>
                            </div>
                        </div>

                        <div class="col-md-12 text-center">
                            <button  class="btn btn-primary" onClick = {() => this.handleOpSave4Vote(this.props.token, this.props.email)}>Save</button>
                        </div>                        
                    </div>
                ) :(
                    <div>
                        <h5 className = "text-without-bg">
                            Please enter your Opinion - {this.props.opTopic} in less than 100 words in the follwoing text field and save it. 
                        </h5>
                        <div class="form-group">
                            <label for="exampleFormControlTextarea1"></label>
                            <textarea class="form-control" id="lifeOpinionTextarea" rows="10">
                             </textarea>
                        </div>

                         <div class="col-md-12 text-center">
                            <button  class="btn btn-primary" onClick = {() => this.handleOpSave(this.props.token, this.props.email)}>Save</button>
                        </div>
                    
                    </div>    
                )
            }

            </div>
         );
    }
} 

export default MyOpinionForm;